import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 14 14",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M5.2 5.2v-.5a.5.5 0 0 0-.5.5zm.06 0h.5a.5.5 0 0 0-.5-.5zm3.54 0v-.5a.5.5 0 0 0-.5.5zm.053 0h.5a.5.5 0 0 0-.5-.5v.5m-3.593.048v.5a.5.5 0 0 0 .5-.5h-.5m-.06 0h-.5a.5.5 0 0 0 .5.5v-.5m3.653 0v.5a.5.5 0 0 0 .5-.5h-.5m-.053 0h-.5a.5.5 0 0 0 .5.5zM5.345 8.561a.5.5 0 1 0-.85.527zm4.16.527a.5.5 0 0 0-.85-.527l.85.527M12.5 7A5.5 5.5 0 0 1 7 12.5v1A6.5 6.5 0 0 0 13.5 7zM7 12.5A5.5 5.5 0 0 1 1.5 7h-1A6.5 6.5 0 0 0 7 13.5zM1.5 7A5.5 5.5 0 0 1 7 1.5v-1A6.5 6.5 0 0 0 .5 7zM7 1.5A5.5 5.5 0 0 1 12.5 7h1A6.5 6.5 0 0 0 7 .5zM5.2 5.7h.06v-1H5.2zm3.6 0h.053v-1H8.8zm-4.04-.5v.048h1V5.2h-1m.5-.452H5.2v1h.06zm.44.5V5.2h-1v.048h1M8.353 5.2v.048h1V5.2zm.5-.452H8.8v1h.053v-1Zm.447.5V5.2h-1v.048h1M7 9.5a1.95 1.95 0 0 1-1.655-.939l-.85.527A2.947 2.947 0 0 0 7 10.5zm1.655-.939C8.302 9.131 7.69 9.5 7 9.5v1c1.06 0 1.983-.568 2.505-1.412l-.85-.527Z"
    }, null, -1)
  ])))
}
export default { render: render }